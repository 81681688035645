(function ($) {
  Drupal.behaviors.heroCarouselBannerFormatterV1 = {
    attach: function (context) {
      var $formatter = $('.js-hero-carousel-banner-formatter-v1', context);
      var $carousel = $();
      var dotsEnable = $formatter.data('dots-enable') === 1;
      var arrowsEnable = $formatter.data('arrows-enable') === 1;
      var arrowsMobileEnable = $formatter.data('arrows-mob-enable') === 1;
      var autoRotate = $formatter.data('auto-rotate') === 1;
      var slideDuration = parseInt($formatter.data('slide-duration'));
      var dotText = [];

      $('.js-hero-banner').each(function (key) {
        dotText[key] = $(this).attr('data-dot-text');
      });

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $formatter.each(function () {
        var $thisFormatter = $(this);
        var arrowsDiv = $thisFormatter.find('.carousel-controls');
        var heroSlide = '.js-hero-carousel__slide';

        $carousel = $thisFormatter.find('.js-hero-carousel');
        var settings = {
          appendArrows: arrowsDiv,
          autoplay: autoRotate,
          autoplaySpeed: slideDuration,
          arrows: arrowsEnable,
          dots: dotsEnable,
          slide: heroSlide,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          customPaging: function (slider, i) {
            var textIndicator = dotText[i];

            return textIndicator;
          },
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendArrows: arrowsDiv,
                autoplay: autoRotate,
                autoplaySpeed: slideDuration,
                arrows: arrowsMobileEnable,
                dots: dotsEnable,
                slidesToShow: 1,
                slidesToScroll: 1,
                customPaging: function (slider, i) {
                  return (
                    '<button type="button" data-role="none" role="button" aria-required="false" tabindex="0">' +
                    (i + 1) +
                    '</button>'
                  );
                }
              }
            }
          ]
        };

        // Init this carousel with our settings
        $carousel.slick(settings);
      });
    }
  };
})(jQuery);
